<template>
  <div class="position">
  <div class="whatsapp_telephone">
    <ul class="icon_list">
      <a href="tel:+79912630683"> 
        <li>
          <FontAwesomeIcon
            icon="phone-alt"
            class="telephone_icon">
          </FontAwesomeIcon>
        </li>
      </a>
      <a href="https://wa.me/79912630683">

        <li>
          <FontAwesomeIcon
            class="whatsapp_icon"
            icon="whatsapp"
            type="fab"
          ></FontAwesomeIcon>
        </li>
      </a>
    </ul>
  </div>
</div>
  <Header />
  <router-view />
  <Footer />
</template>

<script>
import '@/styles/index.css';
import Header from './components/Header.vue';
import Footer from '@/components/Footer.vue';
export default {
  name: 'App',
  components: {
    Header,
    Footer,
  },
};
</script>

<style>
.position {
  position: relative;
}

.whatsapp_telephone {
  position: absolute;
  z-index: 5;
  right: 50px;
  top: 500px;
  bottom: 0;
}

.icon_list {
  position: fixed;
  list-style: none;
  padding: 0;
}

.icon_list li {
  margin-top: 10px;
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 1s ease forwards;
}

.icon_list li:nth-child(1) {
  animation-delay: 0.2s;
}

.icon_list li:nth-child(2) {
  animation-delay: 0.4s;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.telephone_icon,
.whatsapp_icon {
  width: 40px;
  height: 40px;
  padding: 5px;
  border-radius: 50%;
  color: white;
  transition: transform 0.3s ease;
}

.telephone_icon {
  background-color: #a61717;
  
}

.whatsapp_icon {
  background-color: #84C312;
}

.telephone_icon:hover,
.whatsapp_icon:hover {
  transform: scale(1.2);
}

/* Bounce and rotate animations */
.telephone_icon:hover {
  animation: rotateIcon 0.5s ease-in-out;
}

.whatsapp_icon:hover {
  animation: bounceIcon 0.5s ease;
}

/* Bounce effect */
@keyframes bounceIcon {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

@keyframes rotateIcon {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes pulseEffect {
  0% {
    box-shadow: 10 0 0px rgba(0, 0, 0, 0);
  }
  50% {
    box-shadow: 0 0 40px rgba(35, 34, 34, 0.8);
  }
  100% {
    box-shadow: 0 0 15px rgba(0, 0, 0, 0);
  }
}

.telephone_icon,
.whatsapp_icon {
  animation: pulseEffect 2s infinite ease-in-out; /* Pulsing effect every 3 seconds */
}

html,
body {
  width: 100%;
  height: 100%;
}

body {
  font-size: 16px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

img {
  object-fit: cover;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
</style>
