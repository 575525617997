// Services
const state = [
  {
    id: 1,
    title: "УКЛАДКА БРУСЧАТКИ ПОД КЛЮЧ",
    price: "2500",
    additionalInfo: "за м2",
    description: "Цена укладки брусчатки под ключ за м2 сегодня варьируется в пределах 2300-2600 рублей. Наша компания «Ореховское» предлагает более выгодные условия, выполняя работы в строгом соответствии с нормативными требованиями. Мы быстро и качественно уложим плитку, предоставив гарантийные сроки. При возведении покрытий сотрудники используют передовое оборудование и надежные материалы, которые производятся по ГОСТ на нашем предприятии. Для оформления заявки и уточнения информации звоните по телефону +79912630683.",
    mainImage: "/images/services/ukladka-1.png",
    image: "/images/services/ukladka-bruschatki-pod-klyuch1.jpg",
  },

  {
    id: 2,
    title: "ОТМОСТКА ИЗ БРУСЧАТКИ ВОКРУГ ДОМА",
    price: "2200",
    additionalInfo: 'за м2',
    description: "Владельцы частных и загородных домов часто возводят вокруг сооружения отмостку из брусчатки. Она используется для отвода осадков от конструкции, что повышает срок службы фундамента и исключает риск образования луж. Твердое покрытие обладает высокой прочностью и повышает эстетичность. Чтобы правильно выполнить мощение, необходимо соблюдать угол, глубину закладки подушки и другие факторы. Наша компания выполняет укладку под ключ, предоставляя гарантии качества на проведенные работы. Мы также осуществляем продажу плитки и брусчатки по доступным ценам.",
    mainImage: "/images/services/otmostka-brus.png",
    image: "/images/services/scale_1200 (4).webp",
  },
  {
    id: 3,
    title: "МОЩЕНИЕ ДОРОЖЕК БРУСЧАТКОЙ",
    price: "2200",
    additionalInfo: 'за м2',
    description: "При обустройстве тротуаров и прилегающих территорий выгодно проводить мощение дорожек брусчаткой. Это современный вариант твердого покрытия, которое создается из каменных брусков. Они производятся на основе бетона, натурального камня, обожженной глины и других материалов. Использование таких изделий позволяет добиться прочного и долговечного покрытия, создав красивое оформление участка. В ассортименте компании «Ореховское» представлен большой выбор брусчатки в различных цветовых вариациях. Мы не только предлагаем купить высококачественную продукцию, но и оказываем услуги укладки по доступным ценам. Оформить заказ и уточнить интересующую вас информацию можно по телефону +79912630683.",
    mainImage: "/images/services/na-gotovoe-osnovanie.png",
    image: "/images/services/3683c52985676a4f7665050da89e52f1.jpg",
  },
  {
    id: 4,
    title: "УКЛАДКИ ТРОТУАРНОЙ ПЛИТКИ ПОД КЛЮЧ",
    price: "1700",
    additionalInfo: 'за м2',
    description: `Оплата укладки тротуарной плитки под ключ очень часто вызывает сомнения и кажется неоправданной. В эпоху развитых телекоммуникаций, Интернета и ускоренного информационного обмена доступны достаточно подробные инструкции о выполнении тех или иных работ.
    Такая информированность формирует мнение, что всё возможно сделать самостоятельно и для этого достаточно найти в Интернете подробную пошаговую инструкцию. Но от знаний “как делать” до практики, в большинстве случаев, лежит тернистый путь проб и ошибок, пройдя который люди из просто “знающих” становятся мастерами своего дела.

    Например, установка бордюрного камня – кажущаяся простота операции обманчива. Есть около двух десятков нюансов, реализовать которые на практике возможно, лишь имея опыт установки бордюра множество раз.
    `,
    mainImage: "/images/services/ukladka-1.png",
    image: "/images/services/ukladka-pod-klyuch5.jpg",
  },
  {
    id: 5,
    title: "УКЛАДКА ТРОТУАРНОЙ ПЛИТКИ НА БЕТОННОЕ ОСНОВАНИЕ",
    price: "3500",
    additionalInfo: 'за м2',
    description: `
    Ключевым этапом отделки придомовых территорий, парковых зон и дорожек является укладка тротуарной плитки на бетонное основание, цена которой зависит от того, кто и как будет выполнять работы. Наша компания предлагает вариант комплексного обслуживания, то есть производство, продажу и монтаж на объекте с учетом требований строительных нормативов и ваших личных пожеланий. Если вас интересует профессиональная укладка тротуарной плитки в СПБ на бетон, звоните нам по указанным на сайте номерам в любой день. 

    Современное плиточное покрытие позволяет визуально преобразить участок любой конфигурации и размера, а также обеспечить его функциональность. Произведенная на нашем предприятии вибролитая и вибропрессованная плитка радует морозостойкостью, отличными показателями водопоглощения, разнообразием дизайнов и простотой монтажа. Зная, как уложить тротуарную плитку на бетонное основание и в точности следуя технологии, есть возможность обустроить тропинки, двор, место перед гаражом, сад и т.д. за короткое время и с уверенностью в том, что покрытие прослужит в течение длительного периода. 
    `,
    mainImage: "/images/services/ukladka-na-benon.png",
    image: "/images/services/scale_1200 (1).webp",
  },
  {
    id: 6,
    title: "УКЛАДКА ТРОТУАРНОЙ ПЛИТКИ НА ПЕСОК",
    price: "850",
    additionalInfo: 'за м2',
    description: `
    От того, насколько грамотно и оперативно будет выполнена укладка тротуарной плитки на песок, напрямую зависит внешний вид, длительность эксплуатации и практичность готовой поверхности. Если вы намерены выполнять монтажные работы самостоятельно, нужно знать технологические особенности, запастись специальными инструментами и заказать нужное количество материала. Также тротуарная брусчатка может быть уложена профессионалами – наша компания занимается не только изготовлением и продажей плиточных покрытий, но и предоставляет услуги по укладке в Москве и Мособл.

    Уточнить детали комплексного обслуживания вы можете в телефонном режиме, оставив контактные данные для обратного звонка или набрав с мобильного или стационарного номера +79912630683.
    `,
    mainImage: "/images/services/na-pesok.png",
    image: "/images/services/47bceaf33611b5129603e7e5d745addb.jpg",
  },
  {
    id: 7,
    title: "МОЩЕНИЕ ТРОТУАРНОЙ ПЛИТКОЙ",
    price: "3500",
    additionalInfo: 'за м2',
    description: "При желании обустроить участок и сэкономить бюджет, многие владельцы останавливают выбор на мощении тротуарной плиткой. Метод позволяет возвести дорожки и площадки без применения специальных инструментов или оборудования. Технология применима в любых условиях, где покрытие предназначено для передвижения не только людей, но и легкой техники. Способ возведения подбирается с учетом целей эксплуатации и условий местности. Укладка выполняется на песок или бетон. В первом случае выполняется минимум работ, однако он допустим только при плотном и не плавучем грунте. Бетонные основания необходимы для повышения прочности покрытия и позволяют передвигаться автомобилям, а также обустроить стоянку.",
    mainImage: "/images/services/na-gotovoe-osnovanie.png",
    image: "/images/services/ukladka1QWr.png",
  },
  {
    id: 8,
    title: "УКЛАДКА БРУСЧАТКИ НА БЕТОННОЕ ОСНОВАНИЕ",
    price: "2000",
    additionalInfo: 'за м2',
    description: "При обустройстве коммерческих территорий или площадок для стоянки машин часто применяется технология укладки брусчатки на бетонное основание. Метод позволяет значительно увеличить допустимые нагрузки, снижая негативные факторы грунтов и влаги. Процесс выполнения работ отличается сложностью и выполняется в несколько этапов. Во время кладки необходимо соблюдать ряд правил и требований, поскольку ошибки приводят к возникновению проблем в ходе эксплуатации покрытия.",
    mainImage: "/images/services/ukladka-na-benon.png",
    image: "/images/services/scale_1200 (1).webp",
  },
  {
    id: 9,
    title: "УСТАНОВКА БОРДЮРОВ",
    price: "350",
    additionalInfo: 'за п.м',
    description: "Возводя дорожки или площадки на своих участках, владельцы все чаще задумываются о цене установки бордюров. Данный элемент конструкции является необходимым. Без использования бордюрных камней прочность и долговечность покрытия значительно снижаются. Изделие используется в качестве разделителя зон, обеспечивая защиту основания от влаги и воздействия грунтов. Применение бордюров помогает повысить плотность прилегания плитки или брусчатки. За счет этого смещение изделий сводится к минимуму. Однако для монтажа бордюрных камней необходимо не только строго соблюдать правила и этапы работ, но и проводить тщательные замеры. Достичь желаемых результатов в сжатые сроки возможно при помощи услуг специалистов.",
    mainImage: "/images/services/ukladka-bordyur.png",
    image: "/images/services/435805204_w640_h640_ustanovka-bordyura.webp",
  },
  {
    id: 10,
    title: "УКЛАДКА ТРОТУАРНОЙ ПЛИТКИ НА ГОТОВОЕ ОСНОВАНИЕ",
    price: "1200",
    additionalInfo: 'за м2',
    description: `
    В ситуации, когда требуется качественная укладка тротуарной плитки на готовое основание, лучшим решением будет обратиться в нашу специализированную компанию. Мы готовы предложить комплексный сервис, включающий продажу, доставку и монтаж, либо просто выполнить установочные работы на любом объекте в СПб и Ленинградской области. Изучайте прайс укладки тротуарной плитки, чтобы убедиться в выгодности обслуживания, и звоните нам по телефону для обсуждения деталей сотрудничества.

    Потребность в монтаже на подготовленный фундамент возникает довольно часто, и важно найти специалистов, которые сделают все правильно. На то, чтобы сделать все своими руками, потребуется масса усилий, времени, знаний и навыков, поэтому стоит довериться нашим опытным мастерам. Обустройство покрытия на песочном основании и укладка на бетон отличаются между собой, и квалифицированные работники это принимают во внимание, также как соблюдение определенного порядка выполнения установки. От вас требуется лишь договориться о времени приезда бригады, согласовать стоимость услуг и принять готовую работу. 
    `,
    mainImage: "/images/services/na-gotovoe-osnovanie.png",
    image: "/images/services/photo_2021-03-03_17-43-00.jpg",
  },
  {
    id: 11,
    title: "УСТАНОВКА ВОДОСТОКОВ ДЛЯ ТРОТУАРНОЙ ПЛИТКИ",
    price: "500",
    additionalInfo: 'за п.м.',
    description: `
    Обязательным условием обустройства надежного плиточного покрытия дорожек и открытых площадок различного назначения являются водостоки тротуарные, установка которых должна быть выполнена с учетом строительных нормативов. Выгодным и удобным вариантом будет укладка под ключ, которую предлагает компания «Ореховское». Используйте возможность сэкономить время, силы и денежные средства, поручив монтажные работы профессионалам. Мы работаем без перерывов и выходных – обращайтесь за консультацией и для заказа услуги с 9 до 22!
    `,
    mainImage: "/images/services/ukladka-vodostok.png",
    image: "/images/services/photo_2021-03-03_17-44-35.jpg",
  },
  {
    id: 12,
    title: "УКЛАДКА БРУСЧАТКИ",
    price: "2200",
    additionalInfo: 'за м2',
    description: `
    Укладка брусчатки является незаменимым мероприятиемпри обустройстве частных, городских и коммерческих территорий. Материал легко устанавливается, недорого стоит и позволяет возводить надежные покрытия даже на неровном рельефе. При этом характеристики изделий на порядок выше обычной плитки, что позволяет возводить площадки для стоянки техники и автомобилей. Однако в целях соблюдения эксплуатационных требований важно придерживаться правил укладки, которые обеспечивают 50% успеха в работе.
    `,
    mainImage: "/images/services/na-gotovoe-osnovanie.png",
    image: "/images/services/ukladkaQWE.png",
  },
  {
    id: 13,
    title: "УКЛАДКА НА КЛАДБИЩЕ",
    price: "1200",
    additionalInfo: 'за м2',
    description: `
    Оптимальным решением эстетичного оформления места упокоения родственников является укладка тротуарной плитки на кладбище. Изделия помогут перекрыть доступ траве, создать чистоту на участке захоронения, а также упростить уход за могилами. Компания «Ореховское» предоставляет услуги обустройства с использованием качественных материалов. Мы обеспечиваем доступные цены, поскольку самостоятельно производим тротуарную плитку. Сотрудники компании в кратчайшие сроки проведут целый комплекс мероприятий, предоставив гарантии на услуги.
    `,
    mainImage: "/images/services/ukladka-1.png",
    image: "/images/services/enb1.png",
  },
]

const getters = {
  getServiceById: services => id => {
    return services.find(service => service.id == id)
  },
  getServicesByIdList: services => idList => {
    return services.filter(service => idList.includes(service.id))
  }
}

export default {
  state,
  getters
}
