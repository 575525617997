const state = [
  {
    id: 1,
    name: `БРУСЧАТКА 200Х100Х60`,
    info: `Размеры:200х100х60, Количество: 50 шт/ кв.м`,
    type: `vibropress`,
    images: [`/images/products/brastuckanew.png`],
    doneWorks: [
      `/images/our-works/photo_2021-03-06_22-40-52.jpg`,
      `/images/our-works/photo_2021-03-06_22-45-11.jpg`,
      `/images/our-works/photo_2021-03-06_22-42-22.jpg`,
    ],
    prices: [
      `Серый — ${770 + 50} руб кв/м`,
      `Цветной — от ${950 + 50}  руб кв/м`,
      
    ],
    characteristics: [
      `Габаритные размеры, длина/ширина/высота, мм	200х100х60`,
      `Форма	Классическая прямоугольная`,
      `Количество на 1 м.кв	50 штук`,
      `Вес 1 кв. метра	125 кг.`,
      `В поддоне	10 м2`,
      `Вес поддона	1270 кг`,
      `Истираемость продукции	не более 0,7 г/см2`,
      `Водопоглощение	не более 5-6% по массе`,
      `Прочность бетона (на растяжение, при сжатии)	Btb 3,6`,
      `Марка бетона по прочности	М400`,
      `Морозостойкость	F200`,
      `Возможность выбора цвета	6 цветов`,
      `Окрашивание	Полный`,
      `Производитель	Плитка Лэнд`,
    ],
    categorie: `troutar-plitka`,
  },
  {
    id: 2,
    name: `БРУСЧАТКА 200Х100Х80`,
    info: `Размеры:200х100х80, Количество: 50 шт/ кв.м`,
    type: `vibropress`,
    images: [`/images/products/bras2new.jpg`],
    doneWorks: [
      `/images/our-works/photo_2021-03-06_22-40-52.jpg`,
      `/images/our-works/photo_2021-03-06_22-45-11.jpg`,
      `/images/our-works/photo_2021-03-06_22-42-22.jpg`,
    ],
    prices: [
      `Серый — ${870 + 50} руб кв/м`,
      `Цветной — от ${1100 + 50}  руб кв/м`,
    ],
    characteristics: [
      `Габаритные размеры, длина/ширина/высота, мм	200x100x80`,
      `Форма	Классическая прямоугольная`,
      `Количество на 1 м.кв	50 штук`,
      `Вес 1 кв. метра	170 кг.`,
      `В поддоне	10 м2`,
      `Вес поддона	1720 кг`,
      `Истираемость продукции	не более 0,7 г/см2`,
      `Водопоглощение	не более 5-6% по массе`,
      `Прочность бетона (на растяжение, при сжатии)	Btb 3,6`,
      `Марка бетона по прочности	М400`,
      `Морозостойкость	F200`,
      `Возможность выбора цвета	6 цветов`,
      `Окрашивание	Полный`,
      `Производитель	Плитка Лэнд`,
    ],
    categorie: `troutar-plitka`,
  },
  {
    id: 3,
    name: `Новый город 115Х115Х60`,
    info: `Размеры:115х115х60, Количество: 70 шт/ кв.м`,
    type: `vibropress`,
    images: [`/images/products/noviygrd.jpeg`],
    doneWorks: [
      `/images/our-works/photo_2021-03-06_22-54-00.jpg`,
      `/images/our-works/photo_2021-03-06_22-54-08.jpg`,
      `/images/our-works/photo_2021-03-06_22-54-25.jpg`,
    ],
    prices: [
      `Серый — ${900 + 50} руб кв/м`,
      `Цветной — от ${1050 + 50}  руб кв/м`,
    ],
    characteristics: [
      `Габаритные размеры, длина/ширина/высота, мм	115х115х60`,
      `Количество на 1 м.кв	75 штук`,
      `Вес 1 кв. метра	125 кг.`,
      `В поддоне	10 м2`,
      `Вес поддона	1270 кг`,
      `Истираемость продукции	не более 0,7 г/см2`,
      `Водопоглощение	не более 5-6% по массе`,
      `Прочность бетона (на растяжение, при сжатии)	Btb 3,6`,
      `Марка бетона по прочности	М400`,
      `Морозостойкость	F200`,
      `Возможность выбора цвета	6 цветов`,
      `Окрашивание	Полный`,
      `Производитель	Плитка Лэнд`,
    ],
    categorie: `troutar-plitka`,
  },
  {
    id: 5,
    name: `СТАРЫЙ ГОРОД`,
    info: `Размеры: 115х175, 5х60 115х115х60 57, 5х115х60`,
    type: `vibropress`,
    images: [`/images/products/stariygrd.png`],
    doneWorks: [
      `/images/our-works/photo_2021-03-06_22-54-47.jpg`,
      `/images/our-works/photo_2021-03-06_22-55-19.jpg`,
      `/images/our-works/photo_2021-03-06_22-55-26.jpg`,
    ],
    prices: [
      `Серый — ${900 + 50} руб кв/м`,
      `Цветной — от ${1050 + 50}  руб кв/м`,
    ],
    characteristics: [
      `Габаритные размеры, длина/ширина/высота, мм	115х175,5х60/115х115х60/57,5х115х60`,
      `Количество на 1 м.кв	70 штук`,
      `Вес 1 кв. метра	125 кг.`,
      `В поддоне	10 м2`,
      `Вес поддона	1270 кг`,
      `Истираемость продукции	не более 0,7 г/см2`,
      `Водопоглощение	не более 5-6% по массе`,
      `Прочность бетона (на растяжение, при сжатии)	Btb 3,6`,
      `Марка бетона по прочности	М400`,
      `Морозостойкость	F200`,
      `Возможность выбора цвета	6 цветов`,
      `Окрашивание	Полный`,
      `Производитель	Плитка Лэнд`,
    ],
    categorie: `troutar-plitka`,
  },
  {
    id: 6,
    name: `ПАРКЕТ`,
    info: `Размеры: 300x300x30 Количество: 11шт/кв.м`,
    type: `vibroliteo`,
    images: [`/images/products/trotuarnaya_plitka_parket.jpg`],
    doneWorks: [
      `/images/our-works/photo_2021-03-06_22-56-57.jpg`,
      `/images/our-works/photo_2021-03-06_22-57-07.jpg`,
      `/images/our-works/photo_2021-03-06_22-57-17.jpg`,
    ],
    prices: [
      `Серый — ${600 + 50} руб кв/м`,
      `Цветной — от ${700 + 50}  руб кв/м`,
    ],
    characteristics: [
      `Вес (кг)	5,5 кг`,
      `Прочность	М350`,
      `Водопоглощение (%)	5-6 %`,
      `Морозостойкость (ц)	F200`,
      `Штук на поддоне	220 шт`,
      `Коэффициент истираемости	0.55-0․7`,
    ],
    categorie: `troutar-plitka`,
  },
  {
    id: 7,
    name: `ВОЛНА (УЗОР)`,
    info: `Размеры: 300x300x30 Количество: 11 шт/кв.м`,
    type: `vibroliteo`,
    images: [`/images/products/trotuarnaya_plitka_volnakirpich.jpg`],
    prices: [
      `Серый — ${600 + 50} руб кв/м`,
      `Цветной — от ${700 + 50}  руб кв/м`,
    ],
    characteristics: [
      `Вес (кг)	10,5 кг`,
      `Прочность	М300`,
      `Водопоглощение (%)	5-6 %`,
      `Морозостойкость (ц)	F200`,
      `Штук на поддоне	110 шт`,
      `Коэффициент истираемости	0.55-0․7`,
    ],
    categorie: `troutar-plitka`,
  },
  {
    id: 8,
    name: `ТУЧКА`,
    info: `Размеры: 300x300x30 Количество: 11 шт/кв.м`,
    type: `vibroliteo`,
    images: [`/images/products/trotuarnaya_plitka_tuchka.jpg`],
    prices: [
      `Серый — ${600 + 50} руб кв/м`,
      `Цветной — от ${700 + 50}  руб кв/м`,
    ],
    characteristics: [
      `Вес (кг)	6 кг`,
      `Прочность	М300`,
      `Водопоглощение (%)	6 %`,
      `Морозостойкость (ц)	F200`,
      `Штук на поддоне	220 шт`,
      `Коэффициент истираемости	0․7`,
    ],
    categorie: `troutar-plitka`,
  },
  {
    id: 9,
    name: `КАЛИФОРНИЯ`,
    info: `Размеры: 300x300x30 Количество: 11 шт/кв.м`,
    type: `vibroliteo`,
    images: [`/images/products/trotuarnaya_plitka_tuchka.jpg`],
    prices: [
      `Серый — ${600 + 50} руб кв/м`,
      `Цветной — от ${700 + 50}  руб кв/м`,
    ],
    characteristics: [
      `Вес (кг)	6 кг`,
      `Прочность	М300`,
      `Водопоглощение (%)	5-6 %`,
      `Морозостойкость (ц)	F200`,
      `Штук на поддоне	220 шт`,
      `Коэффициент истираемости	0.55-0․7`,
    ],
    categorie: `troutar-plitka`,
  },
  {
    id: 10,
    name: `8 КИРПИЧЕЙ 30`,
    info: `Размеры: 300x300x30 Количество: 11 шт/кв.м`,
    type: `vibroliteo`,
    images: [`/images/products/trotuarnaya_plitka_bruk_8kirpichey.jpg`],
    prices: [
      `Серый — ${600 + 50} руб кв/м`,
      `Цветной — от ${700 + 50}  руб кв/м`,
    ],
    characteristics: [
      `Размеры (мм)	300*300*30`,
      `Вес (кг)	5.5 кг`,
      `Прочность	М300`,
      `Количество в м2	11`,
      `Водопоглощение (%)	5-6 %`,
      `Морозостойкость (ц)	F200`,
      `Штук на поддоне	110 шт`,
      `Коэффициент истираемости	0.55-0․7`,
    ],
    categorie: `troutar-plitka`,
  },
  {
    id: 11,
    name: `3 ДОСКИ`,
    info: `Размеры: 300x300x30 Количество: 6шт/кв.м`,
    type: `vibroliteo`,
    images: [`/images/products/doska.jpg`],
    prices: [
      `Серый — ${600 + 50} руб кв/м`,
      `Цветной — от ${700 + 50}  руб кв/м`,
    ],
    characteristics: [
      `Размеры (мм)	500*500*50`,
      `Вес (кг)	30 кг`,
      `Прочность	М300`,
      `Количество в м2	4 шт`,
      `Водопоглощение (%)	5-6 %`,
      `Морозостойкость (ц)	F200`,
      `Штук на поддоне	40 шт`,
      `Коэффициент истираемости	0․55-0.7`,
    ],
    categorie: `troutar-plitka`,
  },
  {
    id: 11,
    name: `КОВЕР`,
    info: `Размеры: 500x500x50 Количество: 4шт/кв.м`,
    type: `vibroliteo`,
    images: [`/images/products/kover.jpg`],
    prices: [
      `Серый — ${700 + 50} руб кв/м`,
      `Цветной — от ${900 + 50}  руб кв/м`,
    ],
    characteristics: [
      `Размеры (мм)	500*500*50`,
      `Вес (кг)	30 кг`,
      `Прочность	М300`,
      `Количество в м2	4 шт`,
      `Водопоглощение (%)	5-6 %`,
      `Морозостойкость (ц)	F200`,
      `Штук на поддоне	40 шт`,
      `Коэффициент истираемости	0․55-0.7`,
    ],
    categorie: `troutar-plitka`,
  },
  {
    id: 12,
    name: `БУЛЫЖНИК`,
    info: `Размеры: 250x125x60 Количество: 32 шт/кв.м`,
    type: `vibroliteo`,
    images: [`/images/products/trotuarnaya_plitka_angliskiy_bulijnik.jpg`],
    prices: [
      `Серый — ${700 + 50} руб кв/м`,
      `Цветной — от ${900 + 50}  руб кв/м`,
    ],
    characteristics: [
      `Вес (кг)	4,1 кг`,
      `Прочность	М300`,
      `Водопоглощение (%)	5-6 %`,
      `Морозостойкость (ц)	F200`,
      `Штук на поддоне	320 шт`,
      `Коэффициент истираемости	0.55-0․7`,
    ],
    categorie: `troutar-plitka`,
  },
  {
    id: 13,
    name: `КАТУШКА`,
    info: `Размеры: 225x136x50 Количество: 38 шт/кв.м`,
    type: `vibroliteo`,
    images: [`/images/products/trotuarnaya_plitka_katushka.jpg`],
    prices: [
      `Серый — ${700 + 50} руб кв/м`,
      `Цветной — от ${900 + 50}  руб кв/м`,
    ],
    characteristics: [
      `Размеры (мм)	225*136*50`,
      `Вес (кг)	3,5 кг`,
      `Прочность	М400`,
      `Количество в м2	38 шт`,
      `Водопоглощение (%)	6 %`,
      `Морозостойкость (ц)	F300`,
      `Штук на поддоне	380 шт`,
      `Коэффициент истираемости	0.5-0.7`,
    ],
    categorie: `troutar-plitka`,
  },
  {
    id: 14,
    name: `ЭКО БРУСЧАТКА`,
    info: `Размеры: 265x265x60 Количество: 14 шт/кв.м`,
    type: `vibroliteo`,
    images: [`/images/products/eko (1).jpg`],
    prices: [
      `Серый — ${700 + 50} руб кв/м`,
      `Цветной — от ${900 + 50}  руб кв/м`,
    ],
    characteristics: [
      `Вес (кг)	9,7 кг`,
      `Прочность	М300, М350`,
      `Водопоглощение (%)	5-6 %`,
      `Морозостойкость (ц)	F200`,
      `Штук на поддоне	140 шт`,
      `Коэффициент истираемости	0.55`,
    ],
    categorie: `troutar-plitka`,
  },
  {
    id: 15,
    name: `КЛЕВЕР ГЛАДКИЙ`,
    info: `Размеры: 267x218x45 Количество: 28 шт/кв.м`,
    type: `vibroliteo`,
    images: [`/images/products/trotuarnaya_plitka_klever.jpg`],
    prices: [
      `Серый — ${700 + 50} руб кв/м`,
      `Цветной — от ${900 + 50}  руб кв/м`,
    ],
    characteristics: [
      `Размеры (мм)	267*218*60`,
      `Вес (кг)	4.8 кг`,
      `Прочность	М300`,
      `Количество в м2	28 шт`,
      `Водопоглощение (%)	5-6 %`,
      `Морозостойкость (ц)	F200`,
      `Штук на поддоне	280 шт`,
      `Коэффициент истираемости	0․55-0.7`,
    ],
    categorie: `troutar-plitka`,
  },
  {
    id: 16,
    name: `РОМБИК`,
    info: `Размеры: 330x190x45 Количество: 31шт/кв.м`,
    type: `vibroliteo`,
    images: [`/images/products/trotuarnaya_plitka_romb_uzorniy.jpg`],
    prices: [
      `Серый — ${700 + 50} руб кв/м`,
      `Цветной — от ${900 + 50}  руб кв/м`,
    ],
    characteristics: [
      `Размеры (мм)	330*190*45`,
      `Вес (кг)	3,2 кг`,
      `Прочность	М300`,
      `Количество в м2	31 шт`,
      `Водопоглощение (%)	5-6 %`,
      `Морозостойкость (ц)	F200`,
      `Штук на поддоне	310 шт`,
      `Коэффициент истираемости	0.55-0.7`,
    ],
    categorie: `troutar-plitka`,
  },
  {
    id: 17,
    name: `ВОЛНА`,
    info: `Размеры: 210x110x40 Количество: 40шт/кв.м`,
    type: `vibroliteo`,
    images: [`/images/products/trotuarnaya_plitka_volna.jpg`],
    prices: [
      `Серый — ${700 + 50} руб кв/м`,
      `Цветной — от ${900 + 50}  руб кв/м`,
    ],
    characteristics: [
      `Размеры (мм)	210*110*40`,
      `Вес (кг)	2,6 кг`,
      `Прочность	М300`,
      `Количество в м2	40 шт`,
      `Водопоглощение (%)	5-6 %`,
      `Морозостойкость (ц)	F200`,
      `Штук на поддоне	400 шт`,
      `Коэффициент истираемости	0.55-0.7`,
    ],
    categorie: `troutar-plitka`,
  },
  {
    id: 18,
    name: `СТОПА`,
    info: `Размеры: 530x300x45 Количество: 1 шт.`,
    type: `vibroliteo`,
    images: [`/images/products/trotuarnaya-plitka-stopa.jpg`],
    prices: [
      `Серый — ${700 + 50} руб кв/м`,
      `Цветной — от ${900 + 50}  руб кв/м`,
    ],
    characteristics: [
      `Размеры (мм)	530*300*45`,
      `Прочность	М300, М350`,
      `Количество в м2	1`,
      `Водопоглощение (%)	5-6 %`,
      `Морозостойкость (ц)	F200`,
      `Коэффициент истираемости	0.55`,
    ],
    categorie: `troutar-plitka`,
  },
  {
    id: 19,
    name: `ГЖЕЛКА`,
    info: `Размеры:300х300х45, Размеры:220х220х45`,
    type: `vibroliteo`,
    images: [`/images/products/gjelka (5).jpg`],
    prices: [
      `Серый — ${700 + 50} руб кв/м`,
      `Цветной — от ${900 + 50}  руб кв/м`,
    ],
    characteristics: [
      `Размеры (мм)	300*300*45`,
      `Вес (кг)	9,1 кг`,
      `Прочность	М300, М350`,
      `Количество в м2	11 пар`,
      `Водопоглощение (%)	5-6 %`,
      `Морозостойкость (ц)	F200`,
      `Штук на поддоне	110 пар`,
      `Коэффициент истираемости	0.55-0.7`,
    ],
    categorie: `troutar-plitka`,
  },
  {
    id: 20,
    name: `БОРДЮР САДОВЫЙ  500Х200Х40`,
    info: `Количество: 1 шт. Размер: 500x200x40 мм`,
    type: `bordjurnyj-kamen`,
    images: [`/images/products/b-4.jpg`],
    prices: [
      `Серый — ${250 + 50} руб кв/м`,
      `Цветной — от ${370 + 50}  руб кв/м`,
    ],
    categorie: `troutar-plitka`,
  },
  {
    id: 20,
    name: `БОРДЮР САДОВЫЙ ВИБРОПРЕСС 500Х200Х60`,
    info: `Количество: 1 шт. Размер: 500х200х60 мм`,
    type: `bordjurnyj-kamen`,
    images: [`/images/products/b-5.jpg`],
    prices: [
      `Серый — ${180 + 50} руб кв/м`,
      `Цветной — от ${250 + 50}  руб кв/м`,
    ],
    categorie: `troutar-plitka`,
  },
  {
    id: 21,
    name: `БОРДЮР САДОВЫЙ ВИБРОПРЕСС 1000Х200Х80`,
    info: `Количество: 1 шт. Размер: 1000х200х80 мм`,
    type: `bordjurnyj-kamen`,
    images: [`/images/products/bordyur-1000-200-80-2018.jpg`],
    prices: [
      `Серый — ${180 + 50} руб кв/м`,
      `Цветной — от ${250 + 50}  руб кв/м`,
    ],
    categorie: `troutar-plitka`,
  },
  {
    id: 21,
    name: `БОРДЮР ДОРОЖНЫЙ ВИБРОПРЕСС 1000Х300Х150`,
    info: `Количество: 1 шт. Размер: 1000х300х150 мм`,
    type: `bordjurnyj-kamen`,
    images: [`/images/products/magistralniy-bordyur.jpg`],
    prices: [
      `Серый — ${450 + 50} руб./шт.`,
      `Красный — по запросу руб./шт.`,
      `Коричневый — по запросу руб./шт.`,
      `Желтый — по запросу руб./шт.`,
      `Оранжевый — по запросу руб./шт.`,
      `Чёрный — по запросу руб./шт.`,
    ],
    categorie: `troutar-plitka`,
  },
  {
    id: 22,
    name: `БОРДЮР САДОВЫЙ  500Х200Х70`,
    info: `Количество: 1 шт. Размер: 500x200x70 мм`,
    type: `bordjurnyj-kamen`,
    images: [`/images/products/b-4.jpg`],
    prices: [
      `Серый — ${70 + 50} руб./шт.`,
      `Красный — ${90 + 50} руб./шт.`,
      `Коричневый — ${120 + 50} руб./шт.`,
      `Желтый — ${120 + 50} руб./шт.`,
      `Оранжевый — ${180 + 50} руб./шт.`,
      `Чёрный — ${120 + 50} руб./шт.`,
    ],
    categorie: `troutar-plitka`,
  },
  {
    id: 23,
    name: `КАМЕНЬ БОРТОВОЙ БР 100 30 18`,
    info: `Количество: 1 шт. Размер: 1000х300х180 мм`,
    type: `bordjurnyj-kamen`,
    images: [`/images/products/magistralniy-bordyur.jpg`],
    prices: [
      `Серый — ${330 + 50} руб./шт.`,
      `Красный — по запросу`,
      `Коричневый — по запросу`,
      `Желтый — по запросу`,
      `Оранжевый — по запросу`,
      `Чёрный — по запросу`,
    ],
    categorie: `troutar-plitka`,
  },
  {
    id: 23,
    name: `ВОДОСТОК 500Х160Х60`,
    info: `Размеры: 500х160х60 Количество: 1 шт`,
    type: `bordjurnyj-kamen`,
    images: [`/images/products/magistralniy-bordyur.jpg`],
    prices: [
      `Серый — ${330 + 50} руб./шт.`,
      `Красный — по запросу`,
      `Коричневый — по запросу`,
      `Желтый — по запросу`,
      `Оранжевый — по запросу`,
      `Чёрный — по запросу`,
    ],
    categorie: `troutar-plitka`,
  },
  {
    id: 24,
    name: `ОБЛИЦОВКА ЦОКОЛЬНАЯ 270X190`,
    info: `Размеры: 270x190 Количество: 19 шт/ кв.м`,
    images: [`/images/products/oblicovka-cokolnaya190.jpg`],
    prices: [
      `Серая — ${380 + 50} руб кв/м`,
      `Цветная — от ${450 + 50} руб кв / м`,
    ],
    categorie: `fasadnaya-plitka`,
    type: `vibroliteo`,
  },
  {
    id: 25,
    name: `ОБЛИЦОВКА ЦОКОЛЬНАЯ 270X330`,
    info: `Размеры: 270x330 Количество: 30 шт/кв.м`,
    images: [`/images/products/oblicovka-cokolnaya330.jpg`],
    prices: [
      `Серая — ${380 + 50} руб кв/м`,
      `Цветная — от ${450 + 50} руб кв / м`,
    ],
    categorie: `fasadnaya-plitka`,
    type: `vibroliteo`,
  },
  {
    id: 26,
    name: `ОБЛИЦОВКА ЦОКОЛЬНАЯ 270X130`,
    info: `Размеры: 270x130 Количество: 50 шт/ кв.м`,
    images: [`/images/products/oblicovka-cokolnaya120.jpg`],
    type: `vibroliteo`,
    categorie: `fasadnaya-plitka`,
    prices: [
      `Серая — ${380 + 50} руб кв / м`,
      `Цветная — от ${450 + 50} руб кв / м`,
    ],
  },
  {
    id: 27,
    name: `ВОДОСТОК 500Х160Х60`,
    info: `Размеры: 500х160х60 Количество: 1 шт`,
    images: [`/images/products/vodostok-500-160-60-1.jpg`],
    prices: [
      `Серый — ${100 + 50} руб./шт.`,
      `Цветной — от ${150 + 50} руб./ шт.`,
    ],
    categorie: `troutar-plitka`,
  },
  {
    id: 28,
    name: `КРЫШКА НА СТОЛБЫ 390Х390`,
    info: `Размеры: 390х390 Количество: 1 шт`,
    prices: [
      `Серая — ${200 + 50} руб./ шт.`,
      `Цветная — от ${250 + 50} руб./ шт.`,
    ],
    images: [`/images/products/390-390.jpg`],
    categorie: `betonnie-krishki`,
    type: `vibroliteo`,
  },
  {
    id: 29,
    name: `  КРЫШКА НА СТОЛБЫ 450Х450`,
    info: `Размеры: 450х450 Количество: 1 шт`,
    prices: [
      `Серая — ${200 + 50} руб./ шт.`,
      `Цветная — от ${250 + 50} руб./ шт.`,
    ],
    images: [`/images/products/450-450.jpg`],
    categorie: `betonnie-krishki`,
    type: `vibroliteo`,
  },
  {
    id: 30,
    name: `КРЫШКА НА СТОЛБЫ 490Х490`,
    info: `Размеры: 490х490 Количество: 1 шт`,
    prices: [
      `Серая — ${200 + 50} руб./шт.`,
      `Цветная — от ${250 + 50} руб./ шт.`,
    ],
    images: [`/images/products/490-490.jpg`],
    categorie: `betonnie-krishki`,
    type: `vibroliteo`,
  },
  {
    id: 31,
    name: `КРЫШКИ НА ЗАБОРЫ 180Х500`,
    info: `Размеры: 180х500 Количество: 1 шт`,
    prices: [
      `Серая — ${100 + 50} руб./шт.`,
      `Цветная — от ${200 + 50} руб./ шт.`,
    ],
    images: [`/images/products/180-500.jpg`],
    categorie: `betonnie-krishki`,
    type: `vibroliteo`,
  },
  {
    id: 32,
    name: `КРЫШКИ НА ЗАБОРЫ 270Х390`,
    info: `Размеры: 270х390 Количество: 1 шт`,
    prices: [
      `Серая — ${100 + 50} руб./шт.`,
      `Цветная — от ${200 + 50} руб./ шт.`,
    ],
    images: [`/images/products/270-390.jpg`],
    categorie: `betonnie-krishki`,
    type: `vibroliteo`,
  },
  {
    id: 33,
    name: `КРЫШКИ НА ЗАБОРЫ 350x390`,
    info: `Размеры: 350x390 Количество: 1 шт`,
    prices: [
      `Серая — ${120 + 50} руб./шт.`,
      `Цветная — от ${200 + 50} руб./ шт.`,
    ],
    images: [`/images/products/350-390.jpg`],
    type: `vibroliteo`,
    categorie: `betonnie-krishki`,
  },
];

const getters = {
  getProductById: (state) => (id) => {
    return state.find((product) => product.id === id);
  },
  getProductsByCategorieAndType: (state) => (categorie, type) => {
    return state.filter(
      (product) =>
        (product.categorie == categorie || categorie === `any`) &&
        (product.type == type || type == `any`)
    );
  },
};

export default {
  state,
  getters,
};
