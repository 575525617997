<template>
  <header :class="[isHeaderMinimized && 'header--minimized', 'header']">
    <div class="header__view page__view">
      <Topbar :isHeaderMinimized="isHeaderMinimized" />
      <NavbarBurger />
      <Navbar :isHeaderMinimized="isHeaderMinimized" />
    </div>
  </header>
</template>

<script>
import Navbar from './Navbar.vue';
import Topbar from './Topbar.vue';
import NavbarBurger from './NavbarBurger.vue';
export default {
  data() {
    return {
      windowTop: window.top.scrollY,
    };
  },
  name: 'Header',
  components: {
    Navbar,
    NavbarBurger,
    Topbar,
  },
  computed: {
    isHeaderMinimized() {
      return Math.ceil(this.windowTop) > 0;
    },
  },
  mounted() {
    window.addEventListener('scroll', this.onScroll);
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.onScroll);
  },
  methods: {
    onScroll() {
      this.windowTop = window.top.scrollY;
    },
  },
};
</script>

<style scoped>
/* Header */
.header {
  background-image: url('/images/random_grey_variations.png');
  padding: 33px 0;
  z-index: 2;
}

@media (max-width: 1240px) {
  .header {
    padding: 33px 15px;
  }
}

@media (max-width: 880px) {
  .header {
    padding: 15px;
  }
}

.header {
  position: sticky;
  top: 0;
}

.header__view {
  display: grid;
  row-gap: 10px;
  column-gap: 20px;
  height: 100%;
  grid-template-rows: repeat(2, max-content);
}

.header--minimized .header__view {
  grid-template-rows: initial;
  grid-template-columns: 0.3fr 1fr;
}

/* @media (max-width: 880px) {
  .header--minimized .header__view {
    grid-template-rows: initial;
    grid-template-columns: repeat(2, max-content);
    justify-content: space-between;
  }
} */

/* @media (max-width: 880px) {
  .header--minimized .header__view {
    grid-template-columns: max-content max-content;
  }
} */

/* @media (max-width: 730px) {
  .header__view {
    grid-template-rows: initial;
  }
} */

@media (max-width: 880px) {
  .header__view {
    font-size: 1em;
    align-items: center;
  }
}
</style>
