<template>
    <footer class="footer">
        <div class="page__view">
            <div class="footer--main">
                <div class="about-us">
                    <h2>О нас</h2>
                    <p>
                        Наша строительная компания работает на рынке с 2011
                        года. Мы производим бетонную и кованую продукцию,
                        осуществляя поставки товара по Москву и всей Московской
                        области.
                    </p>
                </div>
                <div class="products">
                    <h2>Продукция</h2>
                    <ul class="footer__list">
                        <li
                            class="list__item"
                            v-for="(productsCategorie,
                            idx) of productsCategories"
                            :key="idx"
                        >
                            <router-link
                                :to="`/products/${productsCategorie.value}/any`"
                                class="list__link"
                            >
                                {{ productsCategorie.label }}
                            </router-link>
                        </li>
                    </ul>
                </div>

                <div class="services">
                    <h2>Услуги</h2>
                    <ul class="footer__list">
                        <li class="list__item">
                            <router-link to="/our-works" class="list__link">
                                Наши работы
                            </router-link>
                        </li>
                    </ul>
                </div>

                <div class="contacts">
                    <h2>Контакты</h2>
                    <p class="contact">
                        <b>Адрес:</b> Город Мытищи посёлок Пироговский Совхозная
                        улица 23
                    </p>
                    <p class="contact">
                        <b>Тел.:</b>
                        <a class="tel" href="tel:+79912640683">
                            +7 991 263 0683
                        </a>
                    </p>
                    <p class="contact">
                        <b>Email: </b>
                        <a class="mail" href="mailto:tratuarnayaplitkaru@gmail.com">
                            tratuarnayaplitkaru@gmail.com
                        </a>
                    </p>
                </div>
            </div>
            <div class="footer--copyright">
                <p>© 2024 Все права защищены</p>
            </div>
        </div>
    </footer>
</template>

<script>
import { mapState } from 'vuex';
export default {
    computed: {
        ...mapState(['productsCategories']),
    },
};
</script>

<style scoped>
/* Footer */
.footer {
    background-image: url('/images/random_grey_variations.png');
    color: #c7c6c6;
    padding: 30px;
}
/* Main Footer */
.footer--main {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
}

@media (max-width: 1040px) {
    .footer--main {
        grid-template-columns: 1fr;
    }
}

.footer__list {
    margin-top: 10px;
    list-style-type: none;
}

.list__item {
    border-bottom: 1px solid white;
    padding: 5px;
    cursor: pointer;
}

.list__item:hover {
    background-color: rgba(255, 255, 255, 0.05);
}

.list__link {
    color: inherit;
    text-decoration: none;
    padding: 10px 20px;
    display: block;
}

/* Contacts */

.contacts {
    display: grid;
    row-gap: 10px;
}

.instagram-link {
    background-color: white;
}

/* Footer Copyright */
.footer--copyright {
    margin-top: 10px;
}

.mail,
.tel {
    color: white;
}
</style>
