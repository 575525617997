import { createApp } from 'vue'
import App from './App.vue'
import router from './router/router.js'
import store from './store/store.js'
import { FontAwesomeIcon } from '@/plugins/font-awesome.js'
import './styles/variables.css'


const app = createApp(App)

app.use(store)
app.use(router)
app.component('FontAwesomeIcon', FontAwesomeIcon)
app.mount('#app')
