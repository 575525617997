const mutations = {
  toggleIsNavActive(state) {
    state.isNavActive = !state.isNavActive
  }
}


export default {
  state: {
    isNavActive: false,
  },
  mutations
};
