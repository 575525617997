<template>
  <button
    @click="toggleIsNavActive"
    :class="['navbar-burger', { active: isNavActive }]"
  >
    <span class="navbar-burger__span"></span>
  </button>
</template>
<script>
import { mapState, mapMutations } from 'vuex';
export default {
  computed: {
    ...mapState({
      isNavActive: (state) => state.header.isNavActive,
    }),
  },
  methods: {
    ...mapMutations(['toggleIsNavActive']),
  },
};
</script>

<style lang="scss" scoped>
// Navbar Burger
.navbar-burger {
  height: 2.7em;
  width: 2.7em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-align: left;
  background: none;
  border: none;
  justify-self: end;
  // grid-area: NavbarBurger;
  @media (min-width: 881px) {
    display: none;
  }
}
// Span in Navbar Burger
.navbar-burger__span {
  width: 2.7em;
  height: 6px;
  background: var(--primary-color);
  border-radius: 3px;
  &::before,
  &::after {
    position: absolute;
    content: '';
    background: var(--primary-color);
    width: inherit;
    height: inherit;
    border-radius: inherit;
    transition: transform 0.3s linear;
  }
  &::after {
    transform: translateY(15px) rotate(0deg);
  }
  &::before {
    transform: translateY(-15px) rotate(0deg);
  }
}
// Active Navbar Burger
.navbar-burger.active .navbar-burger__span {
  background: transparent;
  &::after {
    transform: rotate(-45deg);
  }
  &::before {
    transform: rotate(45deg);
  }
}
</style>
