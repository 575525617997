import { library } from '@fortawesome/fontawesome-svg-core'
import { faPhoneAlt, faAngleRight, faAngleDown, faShoppingCart, faTags, faClock, faCogs, faEye, faDownload, faCheck, faTruck, faHeadphones, faSearchPlus, faHeart, faEnvelope, faMapMarkedAlt } from '@fortawesome/free-solid-svg-icons'
import { faInstagram } from '@fortawesome/free-brands-svg-icons'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import FontAwesomeIcon from '@/lib/FontAwesomeIcon.vue'

library.add(
  faPhoneAlt, faAngleRight, faAngleDown,
  faShoppingCart, faTags, faClock,
  faCogs, faEye, faDownload,
  faCheck, faTruck, faHeadphones,
  faSearchPlus, faHeart, faEnvelope,
  faMapMarkedAlt, faWhatsapp
)
library.add(faInstagram,)

export { FontAwesomeIcon }