import { createStore } from 'vuex'
import header from './header.module.js'
import products from './products.module.js'
import services from './services.module.js'

const state = {
  productsCategories: [
    { label: "Тротуарная плитка", value: 'troutar-plitka' },
    { label: "Облицовочная плитка для фасада", value: "fasadnaya-plitka" },
    { label: "Бетонные крышки", value: "betonnie-krishki" }
  ]
}

const getters = {
  getProductsCategorieByValue: state => value => {
    return state.productsCategories.find(productsCategorie => productsCategorie.value === value)
  }
}

const store = createStore({
  state,
  getters,
  modules: {
    header,
    products,
    services
  }
})

export default store;