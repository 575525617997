<template>
  <nav
    :class="[
      isHeaderMinimized && 'header--minimized__navbar',
      'navbar',
      { active: isNavActive },
    ]"
  >
    <ul class="navbar__list">
      <li class="navbar__item" @click="toggleIsNavActive">
        <router-link class="navbar__link" active-class="active" to="/">
          ГЛАВНАЯ
        </router-link>
      </li>
      <li class="navbar__item" @click="toggleIsNavActive">
        <router-link
          class="navbar__link"
          active-class="active"
          to="/about-company"
        >
          О компании
        </router-link>
      </li>
      <li class="navbar__item">
        <div class="navbar__dropdown">
          <router-link
            class="navbar__link"
            active-class="active"
            to="/products"
          >
            Продукция
            <FontAwesomeIcon
              class="dropdown__angle-down-icon"
              icon="angle-down"
            />
          </router-link>
          <div class="dropdown__body">
            <ul class="dropdown__list">
              <li
                class="dropdown__item"
                v-for="(productsCategorie, idx) of productsCategories"
                :key="idx"
                @click="toggleIsNavActive"
              >
                <router-link
                  :to="`/products/${productsCategorie.value}/any`"
                  class="dropdown__link"
                >
                  {{ productsCategorie.label }}
                </router-link>
              </li>
            </ul>
          </div>
        </div>
      </li>
      <li class="navbar__item" @click="toggleIsNavActive">
        <router-link to="/services" class="navbar__link">
          услуги
        </router-link>
      </li>
      <li class="navbar__item">
  <div class="navbar__dropdown">
    <router-link class="navbar__link" active-class="active" to="/sistemy/drainage">
      Благоустройство
      <FontAwesomeIcon class="dropdown__angle-down-icon" icon="angle-down" />
    </router-link>
    <div class="dropdown__body">
      <ul class="dropdown__list">
        <li class="dropdown__item" @click="toggleIsNavActive">
          <router-link
            to="/sistemy/drainage"
            class="dropdown__link"
          >
            Дренажные системы
          </router-link>
        </li>
        <li class="dropdown__item" @click="toggleIsNavActive">
          <router-link
            to="/sistemy/rainwater"
            class="dropdown__link"
          >
            Ливневая канализация
          </router-link>
        </li>
        <li class="dropdown__item" @click="toggleIsNavActive">
          <router-link
            to="/sistemy/lawn"
            class="dropdown__link"
          >
            Укладка газона
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</li>
      <li class="navbar__item" @click="toggleIsNavActive">
        <router-link to="/ukladka" class="navbar__link">
          УКЛАДКА
        </router-link>
      </li>

      <li class="navbar__item" @click="toggleIsNavActive">
        <router-link to="/our-works" class="navbar__link">
          Наши работы
        </router-link>
      </li>
      <li class="navbar__item" @click="toggleIsNavActive">
        <router-link class="navbar__link" active-class="active" to="/exchange">
          ДОСТАВКА И ОПЛАТА
        </router-link>
      </li>
      <li class="navbar__item" @click="toggleIsNavActive">
        <router-link class="navbar__link" active-class="active" to="/contacts">
          контакты
        </router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
export default {
  props: ['isHeaderMinimized'],
  computed: {
    ...mapState({
      isNavActive: (state) => state.header.isNavActive,
    }),
    ...mapState(['productsCategories']),
    ...mapMutations(['toggleIsNavActive']),
  },
};
</script>

<style scoped>
.navbar {
  align-self: end;
}

@media (max-width: 880px) {
  .navbar {
    display: none;
    transition: display 1s;
  }
  .navbar.active {
    display: block;
  }
}

.header--minimized__navbar {
  align-self: center;
  justify-self: flex-start;
}

@media (min-width: 1150px) {
  .header--minimized__navbar {
    justify-self: flex-end;
  }
}

@media (max-width: 880px) {
  .header--minimized__navbar {
    grid-column: 1 / 3;
    justify-self: initial;
  }
}

.navbar__list,
.dropdown__list {
  list-style: none;
}

/* Navbar List */
.navbar__list {
  display: flex;
  column-gap: 10px;
  justify-content: flex-start;
  flex-wrap: wrap;
}

@media (max-width: 880px) {
  .navbar__list {
    flex-direction: column;
    row-gap: 10px;
  }
}

.navbar__item,
.navbar__link {
  display: inline-block;
  cursor: pointer;
  
}

.navbar__link,
.dropdown__link {
  color: inherit;
  text-decoration: none;
}

/* Navbar Link */
.navbar__link {
  text-transform: uppercase;
  color: var(--primary-color);
  border-radius: 5px;
  padding: 10px 13px;
  display: flex;
  align-items: center;
  column-gap: 5px;
}

/* .navbar__link.active,
.navbar__link:hover,
.navbar__dropdown:hover .navbar__link {
  border: 1px solid var(--primary-color);
  color: white;
}

.navbar__dropdown:hover .navbar__link {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;

} */

/* Navbar Dropdown */
.navbar__dropdown:hover .dropdown__body {
  display: block;
  
}

/* Dropdown Body */

.dropdown__body {
  position: absolute;
  display: none;
  background-image: url('/images/random_grey_variations.png');
  padding: 15px 10px;
  border-radius: 5px;
  /* border-top-left-radius: 0; */
  z-index: 2;
  
  /* border: 1px solid var(--primary-color); */
  /* border-top: none; */
}

@media (max-width: 880px) {
  .dropdown__body {
    position: static;
    border-top-right-radius: 0;
  }
}

/* .dropdown__item:hover {
  background-color: #7bd4c6;
} */

/* Dropdown Link */
.dropdown__link {
  display: block;
  padding: 5px 5px;
  text-transform: capitalize;
  color: var(--primary-color);
  line-height: 26px;

}

/* Dropdown Angle Down Icon */
.dropdown__angle-down-icon {
  width: 0.55em;
}
/* Instagram Contianer */
/* .instagram-container {
  width: 2em;
  height: 2em;
  overflow: hidden;
  align-self: center;
  box-shadow: 0.5px 0.5px 3px;
  border-radius: 50%;
  position: relative;
}

@media (max-width: 1004px) {
  .instagram-container {
    display: none;
  }
}

.instagram-container__link {
  color: inherit;
  text-decoration: none;
}

.instagram-container__link:hover > .instagram {
  margin-top: -2em;
}

.instagram {
  width: 2em;
  height: 2em;
  padding: 5px 6px;
  position: absolute;
  transition: 0.3s;
}

.instagram--light {
  color: var(--primary-color);
}

.instagram--dark {
  background-color: var(--primary-color);
  color: white;
}

.instagram--dark {
  top: 2em;
} */
</style>
