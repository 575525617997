<template>
  <div
    :class="[
      isHeaderMinimized && 'header--minimized__topbar',
      'header__topbar',
    ]"
  >
    <router-link class="logo" to="/">
      <img
        class="logo__image"
        alt="лого Ореховсое плитки"
        src="../assets/plitka-msk-logo.png"
      />
    </router-link>
    <div class="topbar__contacts">
      <div class="topbar__contacts__list">
        <li class="topbar__contacts__item">
          <FontAwesomeIcon
            icon="envelope"
            class="tobar__contacts__item__icon icon"
          ></FontAwesomeIcon>
          <a
            href="mailto:tratuarnayaplitkaru@gmail.com"
            class="topbar__link tobar__contacts__item__text"
          >
          tratuarnayaplitkaru@gmail.com
          </a>
        </li>
        <li class="topbar__contacts__item">
          <FontAwesomeIcon
            icon="clock"
            class="tobar__contacts__item__icon icon"
          ></FontAwesomeIcon>
          <span class="tobar__contacts__item__text">
            Работаем с 9:00 до 22:00 без выходных
          </span>
        </li>
        <li class="topbar__contacts__item">
          <FontAwesomeIcon
            icon="map-marked-alt"
            class="tobar__contacts__item__icon icon"
          ></FontAwesomeIcon>
          <span class="tobar__contacts__item__text">
            Город Мытищи посёлок Пироговский Совхозная улица 23
          </span>
        </li>
        <li class="topbar__contacts__item">
          <FontAwesomeIcon
            icon="phone-alt"
            class="tobar__contacts__item__icon icon"
          ></FontAwesomeIcon>
          <a
            href="tel:+79912640683"
            class=" 
          topbar__link topbar__contacts__item__text"
          >
          +7 (991) 2630683
          </a>
        </li>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['isHeaderMinimized'],
};
</script>

<style scoped>
/* Header's Topbar */
.header__topbar {
  display: grid;
  grid-template-columns: 0.3fr 0.7fr;
  justify-content: space-between;
}

@media (max-width: 730px) {
  .header__topbar {
    grid-template-columns: initial;
  }
}

.header--minimized__topbar {
  display: block;
}

.header--minimized__topbar .topbar__contacts {
  display: none;
}

/* Logo */
.logo {
  display: block;
  object-fit: contain;
  text-align: left;
}

.logo__image {
  width: 100%;
  height: 100%;
  width: 200px;
  height: 70px;
}

/* Topbar Link */
.topbar__link {
  color: inherit;
}
/* Contacts List */
.topbar__contacts__list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  font-size: 1.1em;
  row-gap: 10px;
}

@media (max-width: 730px) {
  .topbar__contacts__list {
    grid-template-columns: initial;
    margin-top: 10px;
  }
}

/* Contacts Item */
.topbar__contacts__item {
  list-style: none;
  color: #c7c6c6;
  display: flex;
  gap:10px;
  align-items: center;
}

/* Contacts List Item Icon */
.tobar__contacts__item__icon {
  color: var(--primary-color);
}
</style>
