import { createWebHistory, createRouter } from "vue-router";

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import('@/views/Home.vue'),
  },
  { path: '/products', redirect: '/products/any/any' },
  {
    path: "/products/:productsCategorie/:productsType",
    name: "Products",
    component: () => import("@/views/Products.vue")
  },
  {
    path: "/product/:id",
    name: "Product",
    component: () => import('@/views/Product.vue')
  },
  {
    path: '/contacts',
    name: "Contacts",
    component: () => import('@/views/Contacts.vue')
  },
  {
    path: '/services',
    name: "Services",
    component: () => import("@/views/Services.vue")
  },
  {
    path: '/service/:serviceId',
    name: "Service",
    component: () => import("@/views/Service.vue")
  },
  {
    path: "/exchange",
    name: "Exchange",
    component: () => import("@/views/Exchange.vue")
  },
  {
    path: '/our-works',
    name: "OurWorks",
    component: () => import("@/views/OurWorks.vue")
  },
  {
    path: '/about-company',
    name: "AboutCompany",
    component: () => import("@/views/AboutCompany.vue")
  },
  {
    path: '/ukladka',
    name: "Ukladka",
    component: () => import("@/views/Ukladka.vue")
  },
  {
    path: '/sistemy/drainage',
    name: "DrainageSystems",
    component: () => import("@/views/DrainageSystems.vue")
  },
  {
    path: '/sistemy/rainwater',
    name: "RainwaterSewage",
    component: () => import("@/views/RainwaterSewage.vue")
  },
  {
    path: '/sistemy/lawn',
    name: "LawnLaying",
    component: () => import("@/views/LawnLaying.vue")
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(() => {
  window.scrollTo(0, 0)
  // ...
})

export default router;
